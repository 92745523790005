import React, { useState, useEffect } from "react";
import qs from "qs";
import axios from "axios";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  DatePicker,
  Table,
  Space,
  Tag,
  Pagination,
  Row,
  Col,
  Tooltip,
  message,
} from "antd";

import "moment/locale/zh-cn";
import locale from "antd/es/date-picker/locale/zh_CN";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import {
  GOODS_TYPE,
  WAY,
  filterParams,
  formatTime,
  BUY_USE_TYPE,
  INVENTORY_INFO,
  ALL_CHANNEL,
  DEVICE_ARR,
  distint,
} from "../../../config/constant";
import "./Production.scss";
import { inventoryListApi, channelApi } from "../../../api/api";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function Production() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  // const [system, setSystem] = useState("ios");
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  const [condition, setCondition] = useState({});
  const [channelList, setChannelList] = useState([]);
  const [channelObj, setChannelObj] = useState({});
  const columns = [
    {
      title: "用户ID",
      dataIndex: "user_id",
      key: "user_id",
      width: 100,
      render: (_, record) => (
        <Link
          to={`/user/detail?userId=${record.user_id}&channel=${record.channel}`}
        >
          {record.user_id}
        </Link>
      ),
    },
    {
      title: "用户名称",
      dataIndex: "nick_name",
      key: "nick_name",
      width: 160,
    },
    {
      title: "用户渠道",
      dataIndex: "channel",
      key: "channel",
      width: 200,
      render: (_, record) => <div>{record.channel_name}</div>,
    },
    {
      title: "商品类型",
      dataIndex: "sku_type",
      key: "sku_type",
      width: 100,
      render: (_, record) => <div>{INVENTORY_INFO[record.sku_type]}</div>,
    },
    {
      title: "购买/使用",
      dataIndex: "buy_use_type",
      key: "buy_use_type",
      width: 100,
      render: (_, record) => <div>{BUY_USE_TYPE[record.buy_use_type]}</div>,
    },
    {
      title: "数量",
      dataIndex: "num",
      key: "num",
      width: 100,
    },
    {
      title: "来源",
      dataIndex: "source",
      key: "source",
      width: 150,
      render: (_, record) => {
        const content = record.source.substr(0, 15) + "...";
        return (
          <div>
            {record.source.length >= 18 ? (
              <Tooltip
                placement="topLeft"
                overlayClassName="titleOverSty"
                title={record.source}
              >
                <div>{content}</div>
              </Tooltip>
            ) : (
              <div>{record.source ? record.source : "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "时间",
      dataIndex: "created_at",
      key: "created_at",
      width: 180,
      render: (_, record) => (
        <div>{moment(record.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
      ),
    },
  ];

  const handleSubmit = (values) => {
    let params = {};
    params = filterParams(values);
    getInventory(params, 1);
    setCurrent(1);
    setCondition(params);
  };
  const getInventory = (params, page) => {
    let allParams = {};
    if (params) {
      allParams = { ...params };
      if (allParams.time) {
        const objTime = formatTime(allParams);
        delete allParams.time;
        allParams.start_time = objTime.startTime;
        allParams.end_time = objTime.endTime;
      }
    }
    allParams.token = localStorage.getItem("token");
    allParams.pagination = {};
    allParams.pagination.page = page;
    allParams.pagination.limit = limit;

    // inventory
    axios
      .post(inventoryListApi, {
        ...allParams,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          const { inventory, pagination } = res.data.data;

          setTotal(pagination.total);
          setData(inventory);
        } else {
          message.error("获取列表失败");
        }
      });
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
          setChannelList(newArr);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  useEffect(() => {
    getChannelList();
    const values = form.getFieldsValue();
    if (params.user_id) {
      params.user_id = Number(params.user_id);
      // 必填项，从个人详情页面跳转过来需加参数
      params.buy_use_type = "rmb";
      handleSubmit({ ...params, ...values }, current);
    } else {
      handleSubmit(values, current);
    }
  }, []);
  const pageChange = (value) => {
    setCurrent(value);
    if (params) {
      params.user_id = Number(params.user_id);
      if (condition) {
        getInventory({ ...params, ...condition }, value);
      } else {
        getInventory({ ...params }, value);
      }
    } else {
      if (condition) {
        getInventory({ ...condition }, value);
      } else {
        // 进库存页面
        getInventory("", value);
      }
    }
  };
  const disabledDate = (current) => {
    return current && current >= moment().endOf("day");
  };
  return (
    <div>
      {!params.user_id && (
        <Form
          form={form}
          className="form"
          onFinish={handleSubmit}
          initialValues={{
            sku_type: "all",
            buy_use_type: "use",
            channel: "wechat",
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                label="用户渠道"
                name="channel"
                rules={[
                  {
                    required: true,
                    message: "请输入用户渠道",
                  },
                ]}
              >
                <Select placeholder="请选择用户渠道">
                  {channelList &&
                    channelList.map((item) => (
                      <Option value={item.channel} key={item.label}>
                        {item.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="购买/使用方式"
                name="buy_use_type"
                rules={[
                  {
                    required: true,
                    message: "请输入方式",
                  },
                ]}
              >
                <Select style={{ width: 175 }} placeholder="请输入方式">
                  {WAY.map((item) => (
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="商品类型"
                name="sku_type"
                rules={[
                  {
                    required: true,
                    message: "请输入商品类型",
                  },
                ]}
              >
                <Select style={{ width: "140px" }} placeholder="请输入商品类型">
                  {GOODS_TYPE.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nick_name" label="用户名称">
                <Input
                  placeholder="请输入用户名称：路人甲"
                  style={{ width: "140px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="user_id" label="用户数字ID">
                <Input placeholder="请输入用户数字ID：101293" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="alt_id" label="用户ID">
                <Input placeholder="请输入用户ID：3C8RTZ" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12}>
            <Form.Item label="时间" name="time">
              <RangePicker
                disabledDate={disabledDate}
                locale={locale}
                className="dateSty"
                size="middle"
              />
            </Form.Item>
          </Col>
          <Row className="styButton">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "140" }}
              >
                查询
              </Button>
            </Form.Item>
          </Row>
        </Form>
      )}
      {params.user_id && (
        <Button
          type="primary"
          onClick={() => window.history.back()}
          className="return"
        >
          返回用户详情
        </Button>
      )}
      <Table
        rowKey={() => Math.random()}
        columns={columns}
        dataSource={data}
        onChange={pageChange}
        pagination={false}
        scroll={{
          y: 290,
        }}
      />
      {data && (
        <div className="pagination">
          <Pagination
            current={current}
            total={total}
            defaultPageSize={10}
            onChange={(page) => pageChange(page)}
            showSizeChanger={false}
            showTotal={(total) =>
              `共 ${total} 个库存 第 ${current} / ${Math.ceil(total / 10)} 页`
            }
          />
        </div>
      )}
    </div>
  );
}
